// sizes
$size-huge: 1900px;
$size-extra-large: 1281px;
$size-large: 1025px;
$size-medium: 768px;
$size-small: 481px;
$size-extra-small: 320px;

// colors
$primary-color: #a6c7cc;
$secondary-color: #7cacb4;
$footer-color: #e1eced;
$white: #0000;
$border-color: rgba(54, 66, 81, 0.78);
$shadow-color: rgba(54, 66, 81, 0.39);

// fonts
$primary-cursive-font-family: "Cinzel", serif;
$primary-font-family: "Cormorant Infant", sans-serif;
